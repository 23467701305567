<template>
  <div class="range flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <div class="w-full flex flex-row justify-between items-center">
      <component
        :is="getComponent"
        v-bind="getComponentOptions"
        :model-value="from"
        @update:modelValue="handleUpdate('from', $event)"
      />
      <div>
        {{ $t('components.th_datatable.search_filter.range.to') }}
      </div>
      <component
        :is="getComponent"
        v-bind="getComponentOptions"
        :model-value="to"
        @update:modelValue="handleUpdate('to', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      defaultMin: 0,
      defaultMax: 1000
    }
  },
  computed: {
    localModelValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rangeValue() {
      return {
        from: +this.localModelValue?.from || null,
        to: +this.localModelValue?.to || null
      }
    },
    from() {
      return this.rangeValue.from
    },
    to() {
      return this.rangeValue.to
    },
    getComponent() {
      return this.filter.component || 'el-input'
    },
    getComponentOptions() {
      return { ...this.filter, clearable: false }
    },
    min() {
      return Number.isFinite(this.filter?.min)
        ? this.filter.min
        : this.defaultMin
    },
    max() {
      return Number.isFinite(this.filter?.max)
        ? this.filter.max
        : this.defaultMax
    }
  },
  methods: {
    handleUpdate(type, value) {
      const newRange = { ...this.rangeValue, [type]: value }
      if (newRange.from === null && newRange.to === null) {
        this.localModelValue = null //reset filter
      } else {
        this.localModelValue = {
          from: Number.isFinite(newRange.from) ? +newRange.from : this.min,
          to: Number.isFinite(newRange.to) ? +newRange.to : this.max
        }
      }
    }
  }
}
</script>

<style scoped>
.range :deep(.el-input) {
  max-width: 110px;
}
</style>
